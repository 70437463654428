import { getRequest } from '@/utils/http'

// 获取数据统计总览
export const getDataOverviewTotalCount = (): any =>
  getRequest('/institution/stat/summary')

// 获取资源排行榜
export const getTopCoursewareList = (): any =>
  getRequest('/institution/stat/top-coursewares')

export const getTopSchoolList = (): any =>
  getRequest('/institution/stat/top-share-schools')

export const getTopTeacherList = (): any =>
  getRequest('/institution/stat/top-share-teachers')
